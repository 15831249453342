import React from 'react';
import Header from '../header';
import '../products/products.css';
import { Container, Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ScrollToTopButton from '../ScrollButton';
import Footer from '../footer';
import { Helmet } from 'react-helmet';
import Sidebar from '../sidebar';
import Mobsidebar from '../mobile-sidebar';
import fabrisorb from '../../images/newproductslider/fabrisorb-pro.jpeg';
import farbrisorbdeco from '../../images/newproductslider/fabrosirb-deco-pro.png';
import echoeliminator from '../../images/newproductslider/echoeliminator-pro.jpeg';
import mlvb from '../../images/newproductslider/mlvb-pro.png';
import woodwool from '../../images/newproductslider/woodwool-pro.jpeg';
import decopanel from '../../images/newproductslider/decopanel-pro.jpeg';
import fabriwall from '../../images/newproductslider/fabriwall-pro.jpeg';
import woodtec from '../../images/newproductslider/woodtec-pro.jpeg';
import polyurethane from '../../images/newproductslider/polyurethane-pro.jpeg';
import diffuso from '../../images/newproductslider/diffuso-pro.jpeg';
import polymax from '../../images/newproductslider/polymax-pro.png';
import polysorb from '../../images/newproductslider/polysorb-pro.jpeg';
import polysorbdeco from '../../images/newproductslider/polysorb-deco-pro.png';
import echotherm from '../../images/newproductslider/echotherm-pro.png';
import decowood from '../../images/newproductslider/decowood-pro.jpeg';
import acoustydoor from '../../images/newproductslider/soundproof-door-pro.jpeg';
import knauf from '../../images/newproductslider/knauf-danoline-kraft.png';
import polyester from '../../images/newproductslider/polyester-ceiling-titles-pro.png';


function Products() {

    return (
        <>
            <Helmet>
                <meta name="robots" content="index, follow" />
                <meta name="geo.placename" content="India" />
                <meta http-equiv="content-language" content="in" />
                <meta name="geo.region" content="india" />
                <title>Tailored Acoustic Panels: Manufacturer-Direct Excellence</title>
                <meta name="description" content="" />
                <meta property="og:title" content="Our Acoustic Products" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="https://www.acousticalsurfaces.in/images/logo1.svg" />
                <meta property="og:locale" content="en-in" />
                <link href="https://www.acousticalsurfaces.in/products" rel="alternate" hreflang="en-in" />
                <link rel="canonical" href="https://www.acousticalsurfaces.in/products" />
            </Helmet>
            <section id='products'>
                <div className='container-fluid'>
                    <Header />
                    <div className='mobsidebar'>
                        <div className="col-sm-12">
                            <div className="container">
                                <div className="row">
                                    <Mobsidebar />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Container className='mb-5'>
                        <hr className='product-hr' />
                        <div className='row'>
                            <div className=" col-sm-3 sidemenu">
                                <Sidebar />
                            </div>
                            <div className='col-sm-9'>
                                <Row>

                                    <Col lg={4} sm={4}>
                                        <Card>
                                            <Card.Img className="card-img" variant="top" src={fabrisorb} />
                                            <Card.Body>
                                                <Link className='pro-link' to={'/fabrisorb'}>
                                                    <Card.Title>FabriSorb</Card.Title>
                                                </Link>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={4} sm={4}>
                                        <Card>
                                            <Card.Img className="card-img" variant="top" src={farbrisorbdeco} />
                                            <Card.Body>
                                                <Link className='pro-link' to={'/fabrisorb-deco'}>
                                                    <Card.Title>FabriSorb Deco</Card.Title>
                                                </Link>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={4} sm={4}>
                                        <Card>
                                            <Card.Img className="card-img" variant="top" src={echoeliminator} />
                                            <Card.Body>
                                                <Link className='pro-link' to={'/echo-eliminator'}>
                                                    <Card.Title>Echo Eliminator<sup>TM</sup></Card.Title>
                                                </Link>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={4} sm={4}>
                                        <Card>
                                            <Card.Img className="card-img" variant="top" src={mlvb} />
                                            <Card.Body>
                                                <Link className='pro-link' to={'/mass-loaded-vinyl'}>
                                                    <Card.Title>MLVB</Card.Title>
                                                </Link>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={4} sm={4}>
                                        <Card>
                                            <Card.Img className="card-img" variant="top" src={woodwool} />
                                            <Card.Body>
                                                <Link className='pro-link' to={'/woodwool'}>
                                                    <Card.Title>Wood Wool</Card.Title>
                                                </Link>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={4} sm={4}>
                                        <Card>
                                            <Card.Img className="card-img" variant="top" src={decopanel} />
                                            <Card.Body>
                                                <Link className='pro-link' to={'/decopanel'}>
                                                    <Card.Title>D'ecopanel</Card.Title>
                                                </Link>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={4} sm={4}>
                                        <Card>
                                            <Card.Img className="card-img" variant="top" src={fabriwall} />
                                            <Card.Body>
                                                <Link className='pro-link' to={'/fabriwall'}>
                                                    <Card.Title>FabriWALL</Card.Title>
                                                </Link>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={4} sm={4}>
                                        <Card>
                                            <Card.Img className="card-img" variant="top" src={woodtec} />
                                            <Card.Body>
                                                <Link className='pro-link' to={'/woodtec'}>
                                                    <Card.Title>WoodTEC</Card.Title>
                                                </Link>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={4} sm={4}>
                                        <Card>
                                            <Card.Img className="card-img" variant="top" src={polyurethane} />
                                            <Card.Body>
                                                <Link className='pro-link' to={'/foamstop-pu'}>
                                                    <Card.Title>FOAM STOP<sup>TM</sup>Polyurethane</Card.Title>
                                                </Link>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={4} sm={4}>
                                        <Card>
                                            <Card.Img className="card-img" variant="top" src={diffuso} />
                                            <Card.Body>
                                                <Link className='pro-link' to={'/diffuso'}>
                                                    <Card.Title>Diffuso</Card.Title>
                                                </Link>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={4} sm={4}>
                                        <Card>
                                            <Card.Img className="card-img" variant="top" src={polymax} />
                                            <Card.Body>
                                                <Link className='pro-link' to={'/polymax-polyester'}>
                                                    <Card.Title>Polymax<sup>TM</sup></Card.Title>
                                                </Link>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={4} sm={4}>
                                        <Card>
                                            <Card.Img className="card-img" variant="top" src={polysorb} />
                                            <Card.Body>
                                                <Link className='pro-link' to={'/polysorb'}>
                                                    <Card.Title>Polysorb</Card.Title>
                                                </Link>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={4} sm={4}>
                                        <Card>
                                            <Card.Img className="card-img" variant="top" src={polysorbdeco} />
                                            <Card.Body>
                                                <Link className='pro-link' to={'/polysorb-deco'}>
                                                    <Card.Title>Polysorb Deco</Card.Title>
                                                </Link>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={4} sm={4}>
                                        <Card>
                                            <Card.Img className="card-img" variant="top" src={echotherm} />
                                            <Card.Body>
                                                <Link className='pro-link' to={'/echotherm'}>
                                                    <Card.Title>Echotherm</Card.Title>
                                                </Link>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={4} sm={4}>
                                        <Card>
                                            <Card.Img className="card-img" variant="top" src={decowood} />
                                            <Card.Body>
                                                <Link className='pro-link' to={'/decowood'}>
                                                    <Card.Title>Decowood</Card.Title>
                                                </Link>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={4} sm={4}>
                                        <Card>
                                            <Card.Img className="card-img" variant="top" src={acoustydoor} />
                                            <Card.Body>
                                                <Link className='pro-link' to={'/soundproof-interior-doors'}>
                                                    <Card.Title>Acousty Door</Card.Title>
                                                </Link>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={4} sm={4}>
                                        <Card>
                                            <Card.Img className="card-img" variant="top" src={knauf} />
                                            <Card.Body>
                                                <Link className='pro-link' to={'/knaufdanoline'}>
                                                    <Card.Title>Knauf Danoline Kraft</Card.Title>
                                                </Link>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={4} sm={4}>
                                        <Card>
                                            <Card.Img className="card-img" variant="top" src={polyester} />
                                            <Card.Body>
                                                <Link className='pro-link' to={'/polyester-ceiling-tiles'}>
                                                    <Card.Title>Polyester Ceiling Tiles</Card.Title>
                                                </Link>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <hr />
                    </Container>

                </div>
                <ScrollToTopButton />
            </section>
            <Footer />
        </>
    )
}

export default Products