import React from 'react';
import '../../pages/fabrisorb/slider-fabrisorbceiling';
import echotherm1 from '../../images/newproductslider/echotherm_1.jpeg';
import echotherm2 from '../../images/newproductslider/echotherm_2.jpeg';
import echotherm3 from '../../images/newproductslider/echotherm_3.png';
function SliderEchotherm() {
	return (
	  
	<>
	<div id="slider-fabrisorbceiling">
		<div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
  			<ol className="carousel-indicators">
    			<li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
    			<li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
    			<li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
  			</ol>
  			<div className="carousel-inner">
    			<div className="carousel-item active">
      				<img className="d-block w-100" src={echotherm1} alt="echotherm1" />
	  				<div className="carousel-caption d-none d-md-block">
						<h2>STANDARD COLOURS</h2>
    				</div>
				</div>
    			<div className="carousel-item">
      				<img className="d-block w-100" src={echotherm2} alt="echotherm2" />
	  				<div className="carousel-caption d-none d-md-block">
		  				<h2>STANDARD COLOURS</h2>
    				</div>
				</div>
    			<div className="carousel-item">
      				<img className="d-block w-100 img-fluid" src={echotherm3} alt="echotherm3" />
	  				<div className="carousel-caption d-none d-md-block">
		  				<h2>STANDARD COLOURS</h2>
    				</div>
				</div>
    		</div>
  			<a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    			<span className="carousel-control-prev-icon" aria-hidden="true"></span>
    			<span className="sr-only">Previous</span>
  			</a>
  			<a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    			<span className="carousel-control-next-icon" aria-hidden="true"></span>
    			<span className="sr-only">Next</span>
  			</a>
		</div>
	</div>
	</>
);
}
export default SliderEchotherm;