import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundroom from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import Cadlibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import KIMA1 from '../../images/Project_Images/KIMA_1.jpeg';
import KIMA2 from '../../images/Project_Images/KIMA_2.jpg';
import KIMA3 from '../../images/Project_Images/KIMA_3.jpg';
import ScrollToTopButton from '../ScrollButton';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';

function ProjectKIMA() {
    const sliderRef = useRef(null);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    const goToPrev = () => {
        sliderRef.current.slickPrev();
    };

    const goToNext = () => {
        sliderRef.current.slickNext();
    };

    return (
        <>
            <Helmet>
                <title>About Our Kings International Medical Academy (KIMA)- Auditorium Acoustics Project</title>
                <meta name="description" content="" />
                <meta property="og:title" content="Kings International Medical Academy (KIMA)- Auditorium" />
                <meta property="og:description" content="Top of the line equipment for acoustics and projection giving beautiful ambience topped off with stylish lobby and concession counters screens films of all popular languages." />
                <meta property="og:image" content="https://www.acousticalsurfaces.in/images/Project_Images/Restobar_1.jpg" />
                <meta property="og:locale" content="en-in" />
                <link rel="canonical" href="https://www.acousticalsurfaces.in/acoustic-projects/Project_Images/KIMA_1.jpeg" />
                <link rel="alternate" href="https://www.acousticalsurfaces.in/acoustic-projects/Project_Images/KIMA_1.jpeg" hreflang="en-in" />
                <meta name="geo.placename" content="India" />
                <meta http-equiv="content-language" content="IN" />
                <meta name="geo.region" content="india" />
            </Helmet>
            <Header />
            <section className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 sidemenu">
                            <Sidebar />
                        </div>
                        <div className="col-sm-9 product-page nopaddding">
                            <div className="container">
                                <div className="megamenu">
                                    <Megamenu />
                                </div>
                            </div>
                            <div className="image-slider pro-content">
                                <div id="caption-slide" className="slider-container">
                                    <div className="slider">
                                        <Slider {...settings} ref={sliderRef}>
                                            <div className="slide">
                                                <img src={KIMA1} className="img-fluid" alt="KIMA1" />
                                            </div>
                                            <div className="slide">
                                                <img src={KIMA2} className="img-fluid" alt="KIMA2" />
                                            </div>
                                            <div className="slide">
                                                <img src={KIMA3} className="img-fluid" alt="KIMA3" />
                                            </div>
                                        </Slider>
                                    </div>
                                    <div className="switch" id="prev" onClick={goToPrev} style={{ backgroundColor: "transparent" }}><span>&#10094;</span></div>
                                    <div className="switch" id="next" onClick={goToNext} style={{ backgroundColor: "transparent" }}><span>&#10095;</span></div>
                                </div>
                            </div>
                            <div className="row pro-content">
                                <div className="col-sm-12 writeup">
                                    <h3>Kings International Medical Academy (KIMA)- Auditorium</h3>
                                    <p className="note">Location: Chennai</p>
                                    <p><strong>About Kings International Medical Academy (KIMA)- Auditorium</strong>
                                        <br />Kings International Medical Academy (KIMA) has been a frontrunner, an epitome of medical education based in the beautiful southern city of India, Mamallapuram. From counseling, guidance, and admission to MBBS, Kings International Medical Academy opened new doors of opportunities for students to avail high-quality education at a very low cost and also provides best possible medical training.<br />
                                    </p>
                                    <p>
                                        <strong> Our Scope</strong>
                                        <br />Our scope for this project involves the supply of Fabrisorb panels. These panels are designed to optimize the acoustic environment within the auditorium, ensuring that the spaces are conducive to clear and effective communication.
                                    </p>
                                    <br />
                                    <hr />
                                </div>
                            </div>
                            <div className="row resources">
                                <div className="col-xl-12 col-lg-12 col-sm-12">
                                    <h2>Additional Resources</h2>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-sm-12">
                                    <h6 style={{ textAlign: "center" }}>Creating Better-Sounding Rooms</h6>
                                    <img className="hvr-grow" src={Soundroom} alt="Create Better Sounding Rooms" target="_blank" rel="noopener noreferrer" />
                                    <div className="butn"><Link to="/bs-rooms-guide">Download eBook</Link></div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-sm-12">
                                    <h6 style={{ textAlign: "center" }}>Solutions to Common Noise Problems</h6>
                                    <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems" target="_blank" rel="noopener noreferrer" />
                                    <div className="butn"><Link to="/noise-problems-guide">Download eBook</Link></div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-sm-12">
                                    <h6 style={{ textAlign: "center" }}><br />CAD, CSI, & Revit Library </h6>
                                    <img className="hvr-grow" src={Cadlibrary} alt="CAD Library" target="_blank" rel="noopener noreferrer" />
                                    <div className="butn"><Link to="/cad-library-guide">Access Product Cad Files</Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mobsidebar'>
                        <div className="col-sm-12">
                            <div className="container">
                                <div className="row">
                                    <Mobsidebar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTopButton />
            </section>
            <Footer />
        </>
    )
}

export default ProjectKIMA